import { useEffect, useState } from 'react';
import './Home.css';
import Navbar from '../../components/navbar/Navbar';
import Popup from '../../components/popup/Popup';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Carousel from '../../components/Carousel/Carousel';
import ProductItem from '../../components/ProductItem/ProductItem';
import SitePopup from '../../components/sitePopup/SitePopup';

const Home = () => {
    let navigate = useNavigate();
    const [productsIndex, setProductsIndex] = useState(0);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState(null);
    const [category, setCategory] = useState("");
    const [expanded, setExpanded] = useState({});
    const [crumbs, setCrumbs] = useState([]);
    const [changingCat, setChangingCat] = useState([]);
    const [sitePopup, setSitePopup] = useState(!sessionStorage.getItem("shownPopup"));
    const [popup, setPopup] = useState("");
    const [search, setSearch] = useState("");
    const [pageSize, setPageSize] = useState(10);

    let loading = false;

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}/api/getProducts/0/${pageSize}`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => setProducts(data));
            }
        })

        fetch(`${process.env.REACT_APP_API}/api/getCategories`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => setCategories(data));
            }
        })

        fetch(`${process.env.REACT_APP_API}/api/getPopup`, {})
        .then(response => {
            if (response.status === 200) {
                if (sessionStorage.getItem("shownPopup") == 1) return;
                response.json().then(data => setPopup(data));
            }
        })
    }, []);

    useEffect(() => {
        if (changingCat.length >= 2) {
            setChangingCat([]);
            nextPage();
        }
    }, [changingCat]);

    const prevPage = () => {
        if (productsIndex === 0) return;
        if (category !== "") {
            getProductsByCategory(category, productsIndex - 1);
        } else if (search !== "") {
            getProductsByName(search, productsIndex - 1);
        } else {
            getProducts(productsIndex - 1);
        }
        setProductsIndex(productsIndex - 1);
    }

    const nextPage = () => {
        if (category !== "") {
            getProductsByCategory(category, productsIndex + 1);
        } else if (search !== "") {
            getProductsByName(search, productsIndex + 1);
        } else {
            getProducts(productsIndex + 1);
        }
        setProductsIndex(productsIndex + 1);
    }

    const getProducts = (index) => {
        if (loading) return;

        loading = true;
        fetch(`${process.env.REACT_APP_API}/api/getProducts/${index}/${pageSize}`, {})
        .then(response => {
            setTimeout(() => loading = false, 2000);
            if (response.status === 200) {
                response.json().then(data => {
                    setProducts(data)
                });
            }
        })
    }

    const getProductsByCategory = (category, index) => {
        if (loading) return;

        loading = true;
        fetch(`${process.env.REACT_APP_API}/api/getProductsByCategory/${category.id}/${index}/${pageSize}`, {})
        .then(response => {
            setTimeout(() => loading = false, 2000);
            if (response.status === 200) {
                response.json().then(data => {
                    setProducts(data);
                });
            }
        })
    }

    const toggleCategory = (categoryName, count) => {
        const breadcrumbs = findCategoryBreadcrumbs(categories.home, categoryName.name || categoryName.name_en);

        if (breadcrumbs) {
            setCrumbs(breadcrumbs)
        }

        changeCategory(categoryName)

        setExpanded({
          ...expanded,
          [categoryName.name || categoryName.name_en]: !expanded[categoryName.name || categoryName.name_en]
        });
    }

    const findCategoryBreadcrumbs = (categories, categoryName, currentPath = []) => {
        for (const category of categories) {
            const newPath = [...currentPath, {
                name: category.name_en,
                id: category.id
            }];
    
            if (category.name_en === categoryName) {
                return newPath;
            }
    
            if (category.children && category.children.length > 0) {
                const found = findCategoryBreadcrumbs(category.children, categoryName, newPath);
                if (found) {
                    return found;
                }
            }
        }
    
        return null;
    }

    const changeCategory = (cat) => {
        setProducts([]);
        setSearch("");
        setCategory(cat);
        setProductsIndex(-1);
        setChangingCat([1, 2]);
    }

    const renderCategory = (category, count) => {
        const hasChildren = category.children && category.children.length > 0;
        const isExpanded = expanded[category.name_en || category.name];
    
        return (
          <li key={category.name_en || category.name} className="category-wrapper">
            <div onClick={() => toggleCategory(category, count)} className="category">
              {category.translated ? category.translated: category.name_en || category.name}
              {hasChildren && (isExpanded ? ' -' : ' +')}
            </div>
            {hasChildren && isExpanded && (
              <ul>
                {category.children.map(child => renderCategory(child, count + 1))}
              </ul>
            )}
          </li>
        );
    }

    const handleChangeSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleSearch = () => {
        setCategory("");
        setProducts([]);
        setProductsIndex(0);
        if (search !== "") {
            getProductsByName(search, 0);
        } else {
            getProducts(0);
        }
    }

    const getProductsByName = (name, index) => {
        fetch(`${process.env.REACT_APP_API}/api/getProductsByName/${name.trim()}/${index}/${pageSize}`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setProducts(data)
                });
            }
        })
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          handleSearch();
        }
    };

    return (
        <div className="home">
            {popup && popup.length > 0 && <Popup popup={popup} setPopup={setPopup} />}
            {/* {sitePopup && <SitePopup setPopup={setSitePopup} />} */}

            <Navbar />
            <Carousel />

            <h4 className='homeProductsTitle pt-50' id="productsTitle" >{category ? category.name || category.name_en : 'Products'}</h4>
            <div className="breadcrumbs" >
                {crumbs && crumbs.map((crumb, index) => {
                    return <p className='crumb' onClick={() => {toggleCategory(crumb); changeCategory(crumb)}}>
                        {index > 0 ? (' > ' + crumb.name || crumb.name_en) : crumb.name || crumb.name_en}
                    </p>;
                })}
            </div>
            <div className="home-input-group mb-30 input-splitter" >
                <div style={{ display: 'flex' }}>
                <input value={search} onChange={(e) => handleChangeSearch(e)} onKeyDown={handleKeyDown}></input>
                <button onClick={handleSearch}>Search</button>
                </div>
                
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginLeft: '20px' }}>
                    <button className="homePagingButton" onClick={() => prevPage()}>Prev Page</button>
                    <p style={{ margin: '0px' }}>Page: {productsIndex}</p>
                    <button className="homePagingButton" onClick={() => nextPage()}>Next Page</button>
                </div>
            </div>
            <div className='homeProductsWrapper'>
                {categories && categories.home.length > 0 && <div className='homeCategories mb-50'>
                    <p><strong>Categories</strong></p>
                    {categories && 
                    <ul>
                        {categories.home.map(category => renderCategory(category, 0))}
                    </ul>
                    }
                </div>}

                {products.length > 0 && <div className='homeProducts'>
                    <div className='homeProductsContainer'>
                        {products && products.length > 0 && products.map((product) => {
                            if (!product) return;
                            return <ProductItem data={product} />;
                        })}
                    </div>
                </div>}
            </div>

            <Footer hero={true} />
        </div>
    );
}

export default Home;