import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './CartList.css';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeFromCart, setAmount } from '../../redux/cartSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useState } from 'react';

const CartList = ({ data, showcase, editing, update, printing, creditnote, noMargin = false }) => {
    const [mobile, setMobile] = useState(false);
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const handleChange = (event, id) => {
        event.preventDefault();

        if (editing) {
            update(data.map(x => {
                if (x.id === id) return {...x, amount: event.target.value}
                else return x;
            }));
        } else {
            dispatch(setAmount({
                id: id,
                amount: event.target.value,
            }));
        }
    }

    const handleCreditChange = (event, id) => {
        event.preventDefault();

        update(data.map(x => {
            if (x.id === id) return {...x, creditAmount: event.target.value}
            else return x;
        }));
    }

    const remove = (id) => {
        if (editing) {
            update(data.filter(x => x.id !== id));
        } else {
            dispatch(removeFromCart({
                id: id,
            }));
            toast.error('Removed item from cart.')
        }
    }

    useEffect(() => {
        const screenwidth = window.innerWidth;
        if (screenwidth < 600) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [])

    return (
        <Container maxWidth='lg' className={noMargin ? "p-0" : printing ? "mt-0 p-0" : "mt-50 p-0"}>
            <TableContainer component={Paper}>
                <Table sx={(printing || mobile) ? {maxWidth: mobile ? window.innerWidth : 444} : {minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {creditnote && <TableCell></TableCell>}
                            <TableCell>Name</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Total</TableCell>
                            {(!showcase || editing) && <TableCell></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                className="cart-row"
                            >
                                <TableCell component="th" scope="row" className="cart-cell cart-cell-nopadding" onClick={() => navigate(`/product/${row.id}`)}>
                                    <img src={row.img || row.thumbUrl}></img>
                                </TableCell>
                                {creditnote && <TableCell component="th" scope="row" className="cart-cell cart-cell-nopadding">
                                    <input
                                        type="number"
                                        min={0}
                                        max={row.amount}
                                        value={row.creditAmount || 0}
                                        onChange={(e) => handleCreditChange(e, row.id)}
                                    />
                                </TableCell>}
                                <TableCell className="cart-cell"><span onClick={() => navigate(`/product/${row.id}`)}>{row.name}</span><br></br><small>SKU: {row.SKU ? row.SKU : row.id.length > 20 ? row.id : row.id.replace(/(\d{3})(?=\d)/g, '$1.')}</small></TableCell>
                                <TableCell className="cart-cell">€{row.price.toFixed(2)}</TableCell>
                                <TableCell className="cart-cell">
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {!showcase || editing ? <input
                                            type="number"
                                            min={1}
                                            value={row.amount || row.quantity}
                                            onChange={(e) => handleChange(e, row.id)}
                                        /> : row.amount || row.quantity}
                                        {!row.stock && <small>No stock info available.</small>}
                                        {row.stock && <div>
                                            <small style={{ marginBottom: '0px', marginTop: '0px' }}>{row.stock} items in stock.</small><br></br>
                                            {row.probability === 'HIGH_IN_STOCK' 
                                            ? <small style={{ color: 'green' }}>High in stock.</small> 
                                            : row.probability === 'LOW_IN_STOCK' ? 
                                            <small style={{ color: 'orange' }}>Low items remaining.</small>
                                             : <small style={{ color: 'red' }}>Out of stock!</small>}</div>}
                                    </div>
                                </TableCell>
                                <TableCell className="cart-cell">€{(row.price * (row.amount || row.quantity)).toFixed(2)}</TableCell>
                                {(!showcase || editing) && <TableCell className="cart-cell" onClick={() => remove(row.id)}>X</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default CartList