import { useEffect, useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import './Product.css';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import { useDispatch } from 'react-redux'
import { add } from '../../redux/cartSlice'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Product = () => {
    const params = useParams();
    const [customProduct, setCustomProduct] = useState(false);
    const [stock, setStock] = useState(null);
    const [probability, setProbability] = useState('');
    const [id, setId] = useState('');
    const [product, setProduct] = useState(null);
    const [amount, setAmount] = useState(1);
    const dispatch = useDispatch()

    const navigate = useNavigate();
    
    useEffect(() => {
        if (params.id) {
            setId(params.id);
        } else {
            navigate('/');
        }
    }, [params])

    useEffect(() => {
        if (id) {
            if (id.length > 20) {
                setCustomProduct(true);

                fetch(`${process.env.REACT_APP_API}/api/getCustomProduct/${id}`, {})
                .then(response => {
                    if (response.status === 200) {
                        response.json().then(data => setProduct(data));
                    }
                })
            } else {
                fetch(`${process.env.REACT_APP_API}/api/getProduct/${id}`, {})
                .then(response => {
                    if (response.status === 200) {
                        response.json().then(data => {
                            console.log(data);
                            setProduct(data)
                            setStock(data.stock)
                            setProbability(data.probability)
                        });
                    }
                })
            }
        }
    }, [id])

    const handleChange = (e) => {
        e.preventDefault;

        setAmount(e.target.value);
    }

    return (
        <div className="product-page">
            <Navbar />

            {product && <div className='product mt-50'>
                {customProduct ? <div className="imgWrapper mt-50">
                    <div className="product-img">
                        <img src={product.img} alt={product.name} className="mainImg"></img>
                    </div>
                </div> : 
                    <div className="imgWrapper mt-50">
                    <div className="product-img">
                        <img src={product.image} alt={product.imageAlt} className="mainImg"></img>
                    </div>
                    <div className="product-img">
                        <img src={product.contextualImageUrl} alt={product.imageAlt} className="altImg"></img>
                    </div>
                </div>}
                <div className='product-detail mt-50'>
                    <h4>{customProduct ? product.name : product.name}</h4>
                    <p>{customProduct ? product.description : product.imageAlt.slice(product.name.length + 1)}</p>
                    <h2>€{customProduct ? product.price.toFixed(2) :product.price.currentPrice.toFixed(2)}</h2>
                    {!stock && <p>No stock info available.</p>}
                    {stock && <p>{stock} items in stock. {probability === 'HIGH_IN_STOCK' ? <small style={{ color: 'green' }}>High in stock.</small> : probability === 'LOW_IN_STOCK' ? <small style={{ color: 'orange' }}>Low items remaining.</small> : <small style={{ color: 'red' }}>Out of stock!</small>}</p>}
                    <small>Delivered in 1 week time from order confirmation as per deliveries schedule terms and conditions apply.</small>
                    {!customProduct && product.variants?.length > 0 &&<div className='product-colors'>
                        <h4>Choose Variation</h4>
                        {/* <p>{product.colors.map(x => x.name).join('/')}</p> */}
                        <div className='colors'>
                            {product.variants.map(variant => {
                                return <img src={variant.image} onClick={() => navigate(`/product/${variant.id}`)}></img>
                            })}
                        </div>
                    </div>}
                    <p className="product-productsku mt-20">{customProduct ? product._id : product.id.replace(/(\d{3})(?=\d)/g, '$1.')}</p>
                </div>
            </div>}

                                
            <div className="product mt-0 mb-50">
                <div className="imgWrapper"></div>
                <div className='product-detail'>
                    <div className="product-tools mt-50">
                        <input type="number" min="1" value={amount} onChange={handleChange}></input>
                        <button
                            onClick={() => {
                                dispatch(add({
                                    id: params.id,
                                    name:  customProduct ? product.name : product.name,
                                    price: customProduct ? product.price : product.price.currentPrice,
                                    img: customProduct ? product.img : product.image,
                                    amount: amount,
                                }))
                                toast.success(`${customProduct ? product.name : product.name} added to cart!`)
                            }}
                        >Add To Cart</button>
                    </div>
                </div>
            </div>

            {/* <div className='description'>Product description will go here</div> */}

            <Footer hero={true} />
        </div>
    );
}

export default Product;